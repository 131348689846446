.react-tabs__tab--selected {
    background: #68712e !important;
    border-color: #68712e;
    color: white !important;
    border-radius: 5px 5px 0 0;
    font-weight: 600 !important;
    font-size: 18px !important;
}
.react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    /* list-style: none; */
    padding: 6px 12px;
    cursor: pointer;
    font-weight: 600 !important;
    font-size: 18px !important;
}
.css-1q8wenp-JoyTab-root {
    width: 15vw;
    height: 7vh;
}
.css-1mad5hb-JoyTab-root.Mui-selected{
    width: 15vw;
    height: 7vh;
}
.css-1mad5hb-JoyTab-root:not(.Mui-selected, [aria-selected="true"]) {
    width: 15vw;
    height: 7vh;
}

@media (min-width: 1536px){
    .dashboard {
        padding-right: 15rem !important;
        padding-left: 15rem !important;
    }
}


.tabButton{
    width: 13vw !important;
    text-align: left !important;
}
