@media screen and (max-width: 800px) {
    .MoreService{
    padding: 0px 20px !important;
  }
  }

  
  .multiline-ellipsis {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
    white-space: pre-wrap; /* let the text wrap preserving spaces */
    text-align: justify;
  }


 
  
  /* input[type="file"] {
    display : none;
  }
  
  #file-drag {
    border        : 3px dotted #e5e5e5;
    border-radius : 7px;
    color         : #555;
    cursor        : pointer;
    display       : block;
    padding       : 3em;
    text-align    : center;
    transition    : background 0.3s, color 0.3s;
    background-color: #fafafa;
  }

  .filearea{
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 25px;
  }
  .buttonContainer{
    border: 2px solid #e5e5e5;
    padding: 8px 40px;
    font-weight: bold;
    margin: 15px 0px;
    border-radius: 5px;
  } */