@media screen and (max-width: 800px) {
    .MultiStepForm{
    margin: 20px 0px 0px 0px !important;
  }
 

}
@media screen and (max-width: 900px) {
 
.multistepInput{
  margin-left: 0px !important;
  /* margin-top: 100px !important; */
}
.MainContainer{
  margin-top: 15% !important;
  overflow-y: scroll !important;
  height: 80% !important;
}

}
/* .css-13cymwt-control {
  border-color: black !important;
  background-color: white !important;
} */

.css-3w2yfm-ValueContainer{
    background-color: white !important;
    /* border: 0.5px solid #e5e7eb; */
}
.css-13cymwt-control{
  background-color: white !important;
    /* border: 0.5px solid #e5e7eb; */
    padding-left: 10px !important;
    
}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-color: var(--ck-color-base-border);
  height: 120px !important;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  border-radius: var(--ck-border-radius);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  height: 120px !important;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar, .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-radius: var(--ck-border-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-top: 8px !important;
  --ck-z-default: 0 !important; 
    --ck-z-modal: calc(var(--ck-z-default) + 999) !important;
}



.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 8.5px 14px !important;
  border-color: rgb(241 245 249) !important;
  opacity: 1px !important;
  /* border: 1px solid !important; */
}


.react-tel-input .form-control {
  /* position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 5px;
  line-height: 25px; */
  height: 2.5rem !important;
  width: 100% !important;
  /* margin-top: 30px !important; */
  /* background-color: transparent !important;
    padding-top: 0.5rem!important;
    padding-bottom: 0.5rem !important;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important; */
 
}

/* .GalleryImage{

  background-image: image(); 
} */


/* body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  font-family: Arial, sans-serif;
} */

.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 30px;
}

.thumbnail {
  width: 250px;
  height: 220px;
  /* margin: 10px; */
  cursor: pointer;
}

.full-image-display {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: -1;
}

.full-image {
  max-width: 90vw;
  max-height: 90vh;
}

.buttonImagepre {
  position: fixed;
  top: 60%;
  right: 75%;
  transform: translateY(-50%);
  font-size: 16px;
  padding: 10px;
  background-color: #3498db;
  color: #fff;
  border: none;
  cursor: pointer;
  z-index: 999 !important;
  /* margin: 10px !important; */
}

.buttonImageNext {
  position: fixed;
  top: 60%;
  right: 71%;
  transform: translateY(-50%);
  font-size: 16px;
  padding: 10px;
  background-color: #3498db;
  color: #fff;
  border: none;
  cursor: pointer;
  z-index: 999 !important;
  /* margin: 10px !important; */
}

/* .buttonImage:hover {
  background-color: #2980b9;
}

.buttonImage:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
} */



.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: #68712e !important;
  color: #fff;
  font-weight: 500;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  font-size: 16px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
