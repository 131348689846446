@media screen and (max-width: 800px) {
    .ContainerForm{
    width: 80% !important;
  }
  .Coverbadge{
    margin-left: 60px;
  }
 

}

