@media screen and (max-width: 800px) {
    .IndexSeven{
    margin: 0px !important;
  }
  }


  .react-multiple-carousel__arrow {
    position: absolute;
    outline: 0;
    transition: all .5s;
    border-radius: 35px;
    z-index: 1000;
    border: 0;
    background: rgba(0,0,0,0.5) !important;
    min-width: 43px;
    min-height: 43px;
    opacity: 1;
    cursor: pointer;
    -webkit-transition: all 0.5s ease !important;
    transition: all 0.5s ease !important;
}
.react-multiple-carousel__arrow--right{
  animation-duration: .9s !important;
  animation-timing-function:cubic-bezier(0.83, 0, 0.17, 1) !important;
 
}



@keyframes slideInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slow-motion-text::placeholder {
  opacity: 0;
  animation: slideInFromBottom 1s ease-in-out forwards;
}

.slow-motion-text:focus::placeholder {
  opacity: 1;
  animation: backwards; /* Disable animation when the input is focused */
}


/* .card {
  border: none;
  border-radius: 0;
  box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
}
.carousel-item{
 margin-left: 3.5% !important;
}
.carousel-inner {
  padding: 1em;
}
.carousel-control-prev,
.carousel-control-next {
  background-color: black !important;
  width: 5vh;
  height: 5vh;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}
.carousel-control-prev span,
.carousel-control-next span {
  width: 1.5rem;
  height: 1.5rem;
}

.card-title{
  font-size: 20px !important;
  font-weight: 800;
}
@media screen and (min-width: 577px) {
  .cards-wrapper {
    display: flex;
    width: 100% !important;
  }
  .card {
    margin: 0 0.5em;
    width: calc(100% / 2);
  }
  .image-wrapper {
    height: 12vw;
    margin: 0 auto;
    border-radius: 20px;
  }
}

.image-wrapper img {
  max-width: 100%;
  max-height: 100%;
} */





@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
figure.snip1390 {
  font-family: 'Roboto', Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 230px;
  max-width: 315px;
  width: 100%;
  color: #000000;
  text-align: center;
  font-size: 16px;
  background-color: #fff !important;
  padding: 30px;
  background-image: linear-gradient(-25deg, #fff 0%, #fff 100%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid rgb(60 72 88 / 0.15) !important;
}
figure.snip1390 *,
figure.snip1390 *:before,
figure.snip1390 *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
figure.snip1390 figcaption {
  width: 100%;
}
figure.snip1390 h2,
figure.snip1390 h4,
figure.snip1390 blockquote {
  margin: 0;
}
figure.snip1390 h2,
figure.snip1390 h4 {
  font-weight: 300;
}
figure.snip1390 h2 {
  color: black;
}
figure.snip1390 h4 {
  color: black;
}
figure.snip1390 blockquote {
  color: black;
  font-weight: 500;
  font-size: 1em;
  padding: 45px 20px 40px 50px;
  margin-top: 30px;
  background-color: rgb(5 150 105 / 0.05);
  border-radius: 5px;
  box-shadow: inset -1.4px -1.4px 2px rgba(0, 0, 0, 0.3);
  text-align: left;
  position: relative;
}
figure.snip1390 blockquote:before {
  font-family: 'FontAwesome';
  content: "\201C";
  position: absolute;
  font-size: 70px;
  opacity: 0.8;
  font-style: normal;
  top: 25px !important;
  left: 20px;
color: black !important;
}
figure.snip1390 .profile {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: inline-block;
  /* box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.4); */
  margin-bottom: 10px;
  border: solid 1px #059669;
}





